.container {
  position: relative;
  min-height: calc(100vh - 5rem);
  max-width: 620px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-container {
  margin-bottom: 0.5rem;
  text-align: left;
}

.profile-name,
.profile-title,
.profile-bio {
  margin-bottom: 0.5rem;
}

.profile-picture {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.profile-name {
  margin-bottom: 0.5rem;
}

.links-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.icon-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #fff;
  color: #333;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  transition: all 0.2s ease;
  border: 1px solid #e0e0e0;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.icon-link:hover {
  background-color: #fafafa;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.icon-link svg {
  margin-right: 0.5rem;
}

.text-link {
  color: #0066cc;
  text-decoration: none;
  transition: all 0.2s ease;
  font-weight: 500;
}

.text-link:hover {
  color: #004c99;
  text-decoration: underline;
}

@media (min-width: 600px) {
  .links-container {
    justify-content: flex-start;
  }

  .icon-link {
    width: 200px;
  }
}

.footer {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  font-size: 12px;
  color: #666;
  text-align: center;
  margin-top: 4rem;
}

.profile-bio {
  color: #666;
  font-size: 14px;
  margin-bottom: 2rem;
}
